import { render, staticRenderFns } from "./LabelsAndAgents.vue?vue&type=template&id=34c2bda3&scoped=true&"
import script from "./LabelsAndAgents.vue?vue&type=script&lang=js&"
export * from "./LabelsAndAgents.vue?vue&type=script&lang=js&"
import style0 from "./LabelsAndAgents.vue?vue&type=style&index=0&id=34c2bda3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34c2bda3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VChip,VCombobox,VSimpleTable})
